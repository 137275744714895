.multiselect-container {
  width: 160px;
  border: none;
  background-color: transparent;
  color: #fff;
  height: 45px;
  border: none;
}

.multiselect-container .option {
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  background-color: #313030;
  width: 100%;
  text-align: left;
}

.multiselect-container .option:hover {
  background-color: #5a5858;
}

.multiselect-container .chip {
  background-color: transparent;
  color: #fff;
  border-radius: 15px;
  width: 150px;
  height: 45px;
}

.multiselect-placeholder {
  background-color: transparent;
  color: #ffffff;
  text-align: center;
  border-radius: 100px;
  width: 150px;
}

.multiselect-container-chips {
  background: red;
  width: 150px;
}

.multiselect-chip {
  background-color: #535454; /* Background color for chips */
  color: #fff; /* Text color for chips */
  border-radius: 4px;
  font-size: 12px;
  display: flex;
  align-items: center;
  width: 150px;
  height: 45px;
}

.searchWrapper {
  border-radius: 500px;
  background-color: #535454; 
  width: 150px;
  height: 45px;
  
}

.search-wrapper {
  background-color: #535454; 
  border-radius: 500px;
  padding: 10px;
  color: #fff;
  width: 150px; 
}

.search-wrapper img {
  background-color: #535454;
  border-radius: 500px;
  padding: 10px;
  color: #fff;
  width: 150px;
}

.searchBox  {
  background-color: #535454;
  border: none;
  outline: none;
  color: #fff;
  width: 150px;
}

.multiselect-container ::placeholder {
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  opacity: 1;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  width: 80%;
}

.multiselect-container ::-ms-input-placeholder {
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  opacity: 1;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.multiselect-custom-container .dropdown-btn::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 10px;
  vertical-align: middle;
  border-top: 5px solid white; /* Arrow color set to white */
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

.multiselect-custom-container .dropdown-btn.active::after {
  transform: rotate(180deg); /* Rotate arrow when active */
}

.icon_down_dir {
  display: none;
}

.multiselect-wrapper {
  position: relative;
}

.custom-arrowD {
  position: absolute;
  right: 10px;
  top: 60%;
  transform: translateY(-50%);
  pointer-events: none;  
} 

.custom-arrow {
  position: absolute;
  right: 20px;
  top: 60%;
  transform: translateY(-50%);
  pointer-events: none; 
}


.multiselect-without-border .searchWrapper {
  border: none !important; /* Remove the border */
  box-shadow: none !important;
  background-color: transparent;
}

.multiselect-without-border .search-wrapper {
  border: none !important; /* Remove the border */
  box-shadow: none !important;
  background-color: transparent;
  padding: 5px;  
  height: 100%;
}


.multiselect-without-border .search-wrapper img {
  background-color: #535454;
  border-radius: 500px;
  padding: 5px;
  color: #fff;
  width: 150px;
}
 
.multiselect-without-border:hover .searchWrapper {
  background-color: #535454; 
}
 

.custom-multiselect .optionContainer {
  min-height: 350px;  
  height: 100%;
  overflow-y: auto;
}

.custom-multiselect .optionListContainer {
  min-height: 350px; 
  overflow-y: auto;
  height: 100%;
}
