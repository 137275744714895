/* multiselect-custom.css */

.multiselect-container {
  padding: 2px;
  border:none;  
  background-color: transparent;
  color: #fff;
}

.multiselect-container .option { 
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  background-color: #313030; 
  width: 250px; 
  text-align: left;
}

.multiselect-container .option:hover {
  background-color: #5a5858; 
}

.multiselect-container .chip {
  background-color: transparent;
  color: #fff;
  padding: 5px 15px;
  border-radius: 15px;
  margin: 5px;
}

.multiselect-placeholder { 
    background-color: transparent;
    color: #ffffff; 
    border-radius: 100px;
}

.multiselect-container-chips  {  
  	background: red;
}
 
.multiselect-chip {
    background-color: #535454; /* Background color for chips */
    color: #fff; /* Text color for chips */
    border-radius: 4px;
    padding: 2px 8px;
    font-size: 12px;
    display: flex; 
}
  
.searchWrapper {
    border-radius: 500px;
    background-color: #535454; 
    border : 1px solid linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), rgba(255, 255, 255, 0.06);
    color: #fff;
}

.search-wrapper { 
    background-color: #535454; 
    border-radius: 500px;
    padding: 10px; 
    color: #fff; 
}
 
.searchBox   {
    background-color: #535454; 
    border: none;
    outline: none;
    color: #fff;   
}

.multiselect-container ::placeholder {
    color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
    opacity: 1; 
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;  
    width: 80%;
}
  
.multiselect-container ::-ms-input-placeholder {
    color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
    opacity: 1;  
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    line-height: 20px;
    text-align: center;
}

.multiselect-container .search-wrapper img {
  background-color: #535454;
  border-radius: 500px;
  padding: 5px;
  color: #fff; 
}
 
.multiselect-container:hover .searchWrapper {
  background-color: #535454; 
} 
 
.custom-arrowS {
  position: absolute;  
  right: 65px;  
  top: 48px;  
  transform: translateY(-50%);  
  pointer-events: none;  
  z-index: 1; 
}

.A_custom-arrowS {
  position: absolute;  
  right: 242px;  
  top: 53px;  
  transform: translateY(-50%);  
  pointer-events: none;  
  z-index: 1; 
}
 
.A_custom-arrowC {
  position: absolute;  
  right: 70px;  
  top: 53px;  
  transform: translateY(-50%);  
  pointer-events: none;  
  z-index: 1; 
}

.multiselect-container .optionContainer {
  min-width: 180px;   
  z-index: 111;
}

.multiselect-container .optionListContainer {
  min-width: 180px; 
  z-index: 111;
}
