  .slider-container {  
      width: 100%;  
      height: 100%;   
      display: flex;
      align-items: center;
      justify-content: center; 
      position: relative;
      z-index: 333;
  }
  
  .slider-wrapper {    
    display: flex;
    justify-content: center;
    align-items: center;  
    width: 100%;
    perspective: 800px; 
    max-width: 600px;    
  }
  
  .slider-image-wrapper {      
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out; 
    opacity: 0;  
    align-items: center; 
    position: absolute;
    border-radius: 20px;
    border: 1px solid #FFF;
    height: 530px;  
    width:  530px;   
    background: linear-gradient(123deg, rgba(180, 180, 180, 0.25) 1.74%, rgba(79, 79, 79, 0.25) 1.75%, rgba(255, 255, 255, 0.25) 33.05%, rgba(175, 175, 175, 0.25) 97.16%);
    backdrop-filter: blur(40px);
    padding: 7px;  
    will-change: transform;
  
  }
  
  .slider-image-wrapper img {
    height: 100%;  
    width:  100%;  
    border-radius: 10px; 
  }
 
  .slider-image-wrapper.active {
    transform: translateX(0) scale(1);
    opacity: 1;
    z-index: 2;
  }

  /* Previous image */
  .slider-image-wrapper.prev {
    transform: translateX(-45%) scale(0.7) rotateY(45deg);
    opacity: 0.6;
    z-index: 1;
  }

  /* Next image */
  .slider-image-wrapper.next {
    transform: translateX(45%) scale(0.7) rotateY(-45deg);
    opacity: 0.6;
    z-index: 1;
  }

  /* Slider buttons */
  .slider-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    color: #d2d0d0;
    z-index: 3;
  }
  
  @media   (min-height: 600px) and (max-height: 800px) {
    
    .sliderBox { 
      margin-top: 10px; 
    }

    .slider-container {  
      width: 380px;  
      height: 380px;     
    }
    
    .slider-wrapper {
      width: 380px;
      height: 380px;
    }

    .slider-image-wrapper {
      width: 360px;
      height: 360px;
    }

    .slider-image-wrapper img {
      width: 100%;
      height: 100%; 
    }
     
    .rectangleImages {
      width: 380px;
      height: 380px;
    }
    
}

@media   (min-height: 801px)   {
 
  .slider-container {  
    width: 500px;  
    height: 500px;     
  }
  
  .slider-wrapper {
      width: auto;
      max-height: 500px;
  }

  .slider-image-wrapper {
      width: 480px;
      height: 480px;
  }
 
  .slider-image-wrapper img {
    width: 100%;
    height: 100%; 
  }
  
  .rectangleFrame {
    width: 500px;
    height: 300px; 
  
  } 

  .rectangleImages {
    width: 400px;
    height: 400px;
  }
  
}
 

@media  (min-width: 400px) and (max-width: 600px)  {
    
  .sliderBox { 
    margin-top: 10px; 
  }

  .slider-container {  
    width: 250px;  
    height: 250px;     
  }
  
  .slider-wrapper {
    width: 250px;
    height: 250px;
  }

  .slider-image-wrapper {
    width: 230px;
    height: 230px;
  }

  .slider-image-wrapper img {
    width: 100%;
    height: 100%; 
  }
   
  .rectangleImages {
    width: 250px;
    height: 250px;
  }


}
  
 