.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%); 
  padding: 20px;
  outline: none; 
 
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4); 
  z-index: 100000; /* Ensures the overlay is above other elements */
}

.modalMenu {
  position: fixed;
  top: 0;
  left: 10px;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%); 
  padding: 20px;
  outline: none; 
 
}

.overlayMenu {
  position: fixed;
  top: 0;
  left:10px;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 100000; /* Ensures the overlay is above other elements */
}

.popup { 
  display: flex;
  max-width: 630px;
  width: auto;
  max-height: 620px; 
  padding: 0px  0px  0px  0px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 8px;
  flex-shrink: 0; 
  border-radius: 32px;
  border: 1.4px solid rgba(255, 255, 255, 0.40);
  background: rgba(128, 128, 128, 0.50);
  background-blend-mode: luminosity; 
  /* Blur */
  backdrop-filter: blur(50px);
  
}

.popup_titlebar-and-toolbar{
  display: flex;
  max-width: 650px;
  width: 100%;
  height: 36px;
  justify-content: space-between; 
  flex-shrink: 0;
}

.popup_trailling {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: stretch;
 
}

.popup_trailling_button {
  display: flex; 
  padding: 6px 40px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.popup_trailling_appIcon {
  width: 21px;
  height: 21px;
}

.popup_trailling_label {
 padding-left: 5px;
  color: #FF453A;
  text-align: right;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */
}

.popup_leading {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.popup_trailling_leading_appIcon {
  width: 21px;
  height: 21px;
  position: absolute;
  left: 32px;
  top: 10px;
  fill: #FFF;
}
 
.popup_image {
  max-width:650px;
  height: auto; 

  border-radius: 32px;
  border: 1px solid rgba(0, 0, 0, 0.10);
 
 position: relative;
   margin: 0 auto;
  overflow: hidden;
  /*transition: transform 0.3s ease;*/
}
 

  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    position: relative;
  }
  
  .popup-content img {
    max-width: 100%;
    height: auto;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .image-container {
    position: relative;
    width: 640px;
    height: 600px;
  }
  
 
  .image-zoom-container   {

    max-height: 550px; 
    width: auto;
    min-width: 100px;
    height: 100%;
    border-radius: 32px;   
    border: 1px solid rgba(0, 0, 0, 0.10);
  
  }
   
  
  .zoom-image {
    max-height: 550px; 
    width: auto;
    border-radius: 32px;
    border: 1px solid rgba(0, 0, 0, 0.10);
  
  }

  .magnifier {
    position: relative;
    width: 100px;
    height: 100px;
    overflow: hidden;
    border: 2px solid rgba(255, 255, 255, 0.5);
    background-color: rgba(225, 225, 225, 0.5);
    pointer-events: none;
    z-index: 10000;
  }
  
  .magnifier img {
    position: absolute;
    transform-origin: top left;
    pointer-events: none;
  }

  .image-wrapper {
    max-height: 550px; 
    width: auto;
    min-width: 300px; 
    border-radius: 32px;    
    border: 1px solid rgba(0, 0, 0, 0.10);
  }
  
  .zoom-image2 { 
    max-height: 550px; 
    width: auto;
    height: 100%;
    border-radius: 32px;
    border: 1px solid rgba(0, 0, 0, 0.10);
  }
  
  .zoom-lens {
    position: absolute;
    border: 2px solid #000;
    border-radius: 50%;
    cursor: crosshair;
    pointer-events: none;
    width: 80px;
    height: 80px;
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  .zoom-result {
    position: absolute;
    border: 1px solid #000;
    border-radius: 4px;
    overflow: hidden;
    bottom: 5px;
    right: 0; /* Adjust as needed */
   
    background: rgba(255, 255, 255, 0.5); border-radius: 32px;
  }

  canvas {
    visibility: visible !important;
    pointer-events: auto !important;
  }

  .image-placeholder {
    color: white;
  }